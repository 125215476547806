import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Main from './pages/Main';
import Sidebar from "./components/Sidebar";
import Orders from "./pages/Orders";
import OrderDetail from "./pages/OrderDetail";
import Navbar from "./components/Navbar";
import {useDispatch} from "react-redux";
import {actionTypes} from "./types/masters";
import {useFetching} from "./hooks/useFetching";
import OrderRAPI from "./API/OrderRAPI";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import StatService from "./API/StatService";
import ReactInputMask from "react-input-mask";
import Services from "./pages/Services";
import WarehouseMain from "./pages/WarehouseMain";
import Units from "./pages/Units";
import WorksCalend from "./pages/WorksCalend";
import WorksWoMaster from "./pages/WorksWOMaster";
import Sale from "./pages/Sale";
import Clients from "./pages/Clients";
import ClientDetail from "./pages/ClientDetail";
import StaffSchedule from "./pages/StaffSchedule";
import CheckAccess from "./components/CheckAccess";
import Test from "./pages/Test";
import Auth from "./pages/Auth";
import Settings from "./pages/Settings";
import Statistics from "./pages/Statistics";
import Transacions from "./pages/Transacions";

function App() {

    const [authed, setAuthed] = useState(false)

    const [userData, setUserData] = useState({})

    const [pinSidebar, setPinSidebar] = useState(false)
    const dispatch = useDispatch()

    function togglePinSidebar()
    {
        if (pinSidebar) {
            setPinSidebar(false);
            document.body.classList.remove('g-sidenav-pinned');
        } else {
            setPinSidebar(true);
            document.body.classList.add('g-sidenav-pinned');
        }
    }

    const [fetchRefs, isLoading, errorData] = useFetching(async () => {
        const res = await OrderRAPI.getMainReference()
        dispatch({type: actionTypes.SET_MASTERS, payload: res.masters})
        dispatch({type: actionTypes.SET_ITEM_GROUPS, payload: res.item_groups})
        dispatch({type: actionTypes.SET_ITEM_TYPES, payload: res.item_types})
        dispatch({type: actionTypes.SET_ITEM_MATERIALS, payload: res.item_materials})
        dispatch({type: actionTypes.SET_ITEM_WEARS, payload: res.item_wear})
        dispatch({type: actionTypes.SET_ITEM_SEX, payload: res.item_sex})
        dispatch({type: actionTypes.SET_ITEM_COLOR, payload: res.item_color})
        dispatch({type: actionTypes.SET_USER, payload: res.iam})
        setUserData(res.iam)
    })
    async function amIAuth() {
        const auth = await StatService.amIAuth()
        setAuthed(auth)
        if (auth) {
            fetchRefs()
        }
    }

    async function auth(phone: string, pass: string) {
        const res = await StatService.auth(phone, pass)
        if (res.error) {
            toast.error(res.error)
        } else {
            setAuthed(true)
            fetchRefs()
        }
    }
    async function logout() {
        StatService.logout()
        setAuthed(false)
    }

    useEffect(() => {
        amIAuth()
    }, [])

    if (authed && userData && userData.hasOwnProperty('id')) {
        return (
            <BrowserRouter>
                <div className="Main">
                    <Sidebar pin={pinSidebar}
                        logout={() => logout()}
                        disablePin={() => {
                        setPinSidebar(false);
                        document.body.classList.remove('g-sidenav-pinned');
                    }}/>
                    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                        <Navbar toggleSidebar={() => togglePinSidebar()} user={userData}/>
                        <div className="container-fluid py-4">
                            <Routes>
                                <Route path="/" element={<Main/>}/>
                                <Route path="/orders/:id" element={<OrderDetail/>}/>
                                <Route path="/orders" element={<Orders/>}/>
                                <Route path="/sale" element={<Sale/>}/>
                                <Route path="/services" element={<Services/>}/>
                                <Route path="/warehouse" element={<WarehouseMain/>}/>
                                <Route path="/work_list" element={<WorksCalend/>}/>
                                <Route path="/appoint" element={<WorksWoMaster/>}/>
                                <Route path="/clients" element={<Clients/>}/>
                                <Route path="/clients/:id" element={<ClientDetail/>}/>
                                <Route path="/calendar/" element={<StaffSchedule/>}/>
                                <Route element={<CheckAccess accesses={['all_zp', 'own_zp']}/>}>
                                    <Route path="/units" element={<Units/>}/>
                                </Route>
                                <Route path="/settings" element={<Settings/>}/>
                                <Route path="/statistics" element={<Statistics/>}/>
                                <Route path="/transactions" element={<Transacions/>}/>
                            </Routes>
                        </div>
                    </main>
                    <ToastContainer autoClose={3000} position="bottom-right"/>
                </div>
            </BrowserRouter>
        )
    } else {

        return (

            <BrowserRouter>
                <Routes>
                    <Route path="/test" element={<Test/>}/>
                    <Route path="*" element={<Auth auth={auth}/>}/>
                </Routes>
            </BrowserRouter>
        )
    }
}

export default App;
