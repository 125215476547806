import axios from "axios";
import {ClientType} from "../models/OrderModels";

export default class OrderRAPI {
    static async getAll(search: string, page: number = 1, status: string = '') {
        return (await axios.get('orders?limit=20&search=' + search +'&page=' + page +'&status_filter=' + status)).data
    }
    static async getById(id: number) {
        return (await axios.get('orders/' + id)).data
    }
    static async getLog(id: number) {
        return (await axios.get('orders/' + id + '/log/')).data
    }
    static async getMainReference() {
        return (await axios.get('orders/references/')).data
    }
    static async reCalcSum(id: number) {
        return (await axios.get('orders/' + id + '/recalc_sum/')).data
    }
    static async setIssue(id: number, code: string) {
        return (await axios.post('orders/' + id + '/issue/', {
            code: code
        })).data
    }
    static async setClient(id: number, clientId: number): Promise<ClientType> {
        return (await axios.post('orders/' + id + '/set_client/', {
            client_id: clientId
        })).data
    }
    static async setCreated(id: number, desired_at: string, discount: number) {
        return (await axios.post('orders/' + id + '/set_created/', {
            desired_at: desired_at,
            discount: discount
        })).data
    }
    static async setComment(id: number, comment: string) {
        return (await axios.post('orders/' + id + '/set_comment/', {
            comment: comment
        })).data
    }
    static async delete(id: number) {
        return (await axios.post('orders/' + id + '/delete/', {})).data
    }
    static async addSale(items: any[]) {
        return (await axios.post('orders/sale/', {
            items: items
        })).data
    }
    static async getBusy(year: number, month: number) {
        return (await axios.get('orders/busy/?year=' + year + '&month=' + month)).data
    }
}