import React, {useEffect, useState} from 'react';
import {useTypedSelector} from "../hooks/useTypedSelector";
import ServiceRAPI from "../API/ServiceRAPI";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useTitle} from "../hooks/useTitle";
import {useAccess} from "../hooks/useAccess";

const WorksCalend = () => {
    useTitle('Календарь задач')
    const navigate = useNavigate();

    function goToOrder(id) {
        navigate('/orders/' + id)
    }

    const user = useTypedSelector(state => state.itemRefs.user)

    const [Worker, SetWorker] = useState(useAccess('all_calendar') ? 'all': user.id)
    const [search, setSearch] = useState('')
    const [List, SetList] = useState([])
    const masters = useTypedSelector(state => state.masters)

    function filterMasters(masters) {
        return masters.filter((x) => {
            return x.role_id !== 1 && x.active
        })
    }

    const activeMasters = filterMasters(masters.masters)

    async function getList()
    {
        const res = await ServiceRAPI.WorkList(Worker)

        // {Object.keys(List[group]).map(k =>
        //     <tr>
        //         <td>{List[group][k].item_id}</td>
        //     </tr>
        // )}
        if (res.error) {
            toast.error(res.error)
            SetList([])
        } else {
            SetList(res)
        }
    }

    function renderList()
    {
        let arr = []
        let cnt = 0
        Object.keys(List).map(group => {
            let color = '#EEE'
            if (group.substr(4) === 'Заморожено') color = '#8CF'
            if (group.substr(4) === 'Просрочено') color = '#FAA'
            if (group.substr(4) === 'Сегодня') color = '#FC8'

            arr.push({type: 'title', i: cnt, color: color, title: group.substr(4)})
            cnt++
            let befcnt = cnt
            List[group].filter(el => el.item_id.toLowerCase().includes(search) || el.client.toLowerCase().includes(search) || el.label.includes(search)).map(el => {
                arr.push({...el, type: 'elem', i: cnt})
                cnt++
            })
            if (befcnt === cnt) {
                arr.pop()
            }
        })

        return arr
    }

    useEffect(function () {
        getList()
    }, [Worker])

    useEffect(function () {
        renderList()
    }, [List])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="d-sm-flex justify-content-between">
                    <div className="d-flex">
                        <input type="text" className="form-control mb-3" placeholder="Поиск по заказам" style={{height: '41px'}} value={search} onChange={(e) => setSearch(e.target.value.toLowerCase())}/>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                    {useAccess('all_calendar') &&
                    <div className="p-3" style={{display: 'flex', gap: '0 20px', flexWrap: 'wrap'}}>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="radio" id={'flexSwitchCheck_all'} name="bubu" value="all" checked={Worker === 'all'} onChange={(e) => SetWorker(e.currentTarget.value)}/>
                            <label className="form-check-label" htmlFor={'flexSwitchCheck_all'}>Все</label>
                        </div>
                        {activeMasters.map(v =>
                            <div className="form-check form-switch" key={v.id}>
                                <input className="form-check-input" type="radio" id={'flexSwitchCheck_' + v.id} name="bubu" value={v.id} onChange={(e) => SetWorker(e.currentTarget.value)}/>
                                <label className="form-check-label" htmlFor={'flexSwitchCheck_' + v.id}>{v.fio.replace(' ', '\u00A0')}</label>
                            </div>
                        )}
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="radio" id={'flexSwitchCheck_none'} name="bubu" value="none" onChange={(e) => SetWorker(e.currentTarget.value)}/>
                            <label className="form-check-label" htmlFor={'flexSwitchCheck_none'}>Без исполнителя</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="radio" id={'flexSwitchCheck_cons'} name="bubu" value="cons" onChange={(e) => SetWorker(e.currentTarget.value)}/>
                            <label className="form-check-label" htmlFor={'flexSwitchCheck_cons'}>Консультация</label>
                        </div>
                    </div>
                    }
                    <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Заказ</th>
                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Дата</th>
                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Клиент</th>
                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Работа</th>
                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Исполнитель</th>
                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Цена</th>
                                </tr>
                            </thead>
                            <tbody>
                            {renderList().map(el =>
                                <tr key={el.i}>
                                    {el.type === 'title' &&
                                    <td className="text-center text-bold" colSpan="6" style={{backgroundColor: el.color}}>{el.title}</td>
                                    }
                                    {el.type === 'elem' &&
                                        <>
                                            <td dangerouslySetInnerHTML={{__html: el.item_id}} style={{cursor: 'pointer'}} onClick={() => goToOrder(el.order_id)}/>
                                            <td>{el.date}</td>
                                            <td dangerouslySetInnerHTML={{__html: el.client}}/>
                                            <td className="text-wrap" dangerouslySetInnerHTML={{__html: el.label}}/>
                                            <td>{el.master}</td>
                                            <td>{el.price} {el.discount !== 0 &&
                                                <span className="text-sm">{el.discount}%</span>
                                            }</td>
                                        </>
                                    }
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorksCalend;