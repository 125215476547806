import React, {useEffect, useState} from 'react';
import {ClientType} from "../../models/OrderModels";
import ClientRAPI from "../../API/ClientRAPI";
import ClientCreateModal from "../Order/ClientCreateModal";
import {useNavigate} from "react-router-dom";

const ClientCard = (props: {client:ClientType, setClient: (clientId: number) => void}) => {

    const [focused, setFocused] = useState(false)
    const [search, setSearch] = useState('')
    const [suggestedClients, setSuggestedClients] = useState<ClientType[]>([])

    function fio() {
        return [props.client.last_name, props.client.name, props.client.second_name].join(' ')
    }

    async function getSuggests()
    {
        const res = await ClientRAPI.searchSuggest(search, 5)

        setSuggestedClients(res)
    }

    let formatPhoneNumber = (str: string) => {
        let cleaned = ('' + str).replace(/\D/g, '');

        let match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
            let intlCode = (match[1] ? '+7 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('')
        }

        return null;
    }

    useEffect(function() {
        if (!props.client) {
            getSuggests()
        }
    }, [search])

    const [modalAddClient, setModalAddClient] = useState(false)

    const navigate = useNavigate();

    return (
        <div className={'card h-100' + (props.client ? ' cursor-pointer' : '')} onClick={() => props.client ? navigate('/clients/' + props.client.id) : ''}>
            <div className="card-header pb-0 p-3">
                <div className="row">
                    <div className="col-md-8 d-flex align-items-center">
                        <h6 className="mb-0">Клиент</h6>
                        &nbsp;<span className="badge bg-gradient-warning">{props.client.type}</span>
                    </div>
                </div>
            </div>
            <div className="card-body p-3">
                {!props.client &&
                    <div>
                        <input type="text" className="form-control" placeholder="Поиск..." value={search} onChange={(e) => setSearch(e.target.value)} onFocus={() => setFocused(true)} onBlur={() => setTimeout(function (){setFocused(false)}, 200)} />
                        {focused &&
                            <div style={{position: "relative"}}>
                                <div className="bg-gradient-primary py-1" style={{position: 'absolute', zIndex: 1, width: "max-content", borderRadius: '5px', color: '#fff', cursor: "pointer"}}>
                                    {suggestedClients.length > 0 && suggestedClients.map(el =>
                                        <div className="px-3 py-1 hovtrans" onClick={() => props.setClient(el.id)}><b>{formatPhoneNumber(el.phone.toString())}</b> {el.last_name} {el.name} {el.second_name}</div>
                                    )}
                                </div>
                            </div>
                        }
                        <button className="btn bg-gradient-primary mt-3" onClick={() => setModalAddClient(true)}>Добавить</button>
                        <ClientCreateModal setClient={props.setClient} closeModal={() => setModalAddClient(false)} modal={modalAddClient}/>
                    </div>
                }
                {props.client &&
                    <ul className="list-group">
                        <li className="list-group-item border-0 ps-0 pt-0 text-sm"><strong
                            className="text-dark">ФИО:</strong> &nbsp; {fio()}
                        </li>
                        <li className="list-group-item border-0 ps-0 text-sm"><strong
                            className="text-dark">Телефон:</strong> &nbsp; {formatPhoneNumber(props.client.phone.toString())}</li>
                        <li className="list-group-item border-0 ps-0 text-sm"><strong
                            className="text-dark">Скидка:</strong> &nbsp; {props.client.discount}%
                        </li>
                        {props.client && props.client.comment && props.client.comment.length > 0 &&
                        <li className="list-group-item border-0 ps-0 text-sm"><strong
                            className="text-dark">Комментарий:</strong> &nbsp; {props.client.comment}
                        </li>
                        }
                        {props.client && props.client.deposit && parseInt(props.client.deposit) != 0 &&
                        <li className="list-group-item border-0 ps-0 text-sm"><strong
                            className="text-dark">Депозит:</strong> &nbsp; {props.client.deposit}
                        </li>
                        }
                        {props.client && props.client.represes && props.client.represes.length > 0 &&
                        <li className="list-group-item border-0 ps-0 text-sm"><strong
                            className="text-dark">Представители:</strong>
                            {props.client.represes.map((elem) =>
                                <p className="text-sm" key={elem.p}>{elem.f} {elem.p}</p>
                            )}
                        </li>
                        }
                    </ul>
                }
            </div>
        </div>
    );
};

export default ClientCard;