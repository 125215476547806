import axios from "axios";

export default class ItemRAPI {
    static async setWarehouse(itemId: number, rack: string, shelf: string) {
        return (await axios.post('items/' + itemId + '/warehouse/', {
            rack: rack,
            shelf: shelf
        })).data
    }
    static async setIssue(itemId: number, code: string) {
        return (await axios.post('items/' + itemId + '/issue/', {
            code: code
        })).data
    }
    static async returnWarranty(itemId: number) {
        return (await axios.post('items/' + itemId + '/return_warranty/')).data
    }
    static async setFreeze(itemId: number) {
        return (await axios.post('items/' + itemId + '/freeze/')).data
    }
    static async addItem(Item: {
        order_id: number,
        group: string,
        type: string,
        material: string,
        color: string,
        desired_at: string,
        comment: string,
        sex: string,
        wear: string,
    }) {
        return (await axios.post('items/', {
            order_id: Item.order_id,
            group: Item.group,
            type: Item.type,
            material: Item.material,
            color: Item.color,
            desired_at: Item.desired_at,
            comment: Item.comment,
            sex: Item.sex,
            wear: Item.wear,
        })).data
    }
    static async setDesire(itemId: number, date: string, comment: string) {
        return (await axios.post('items/' + itemId + '/desire/', {
            date: date,
            comment: comment
        })).data
    }
    static async getTicket(itemId: number) {
        return (await axios.post('items/' + itemId + '/print_ticket/')).data
    }
    static async setComment(itemId: number, comment: string) {
        return (await axios.post('items/' + itemId + '/comment/', {
            comment: comment
        })).data
    }
    static async delete(itemId: number) {
        return (await axios.post('items/' + itemId + '/delete/')).data
    }
}