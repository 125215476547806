import React, {useEffect, useState} from 'react';
import StatCard from "../components/UI/StatCard";
import {useFetching} from "../hooks/useFetching";
import StatService from "../API/StatService";
import PaymentService from "../API/PaymentService";
import Modal from "../components/UI/Modal";
import Moment from 'moment';
import PaymentAdd from "../components/PaymentAdd";
import WarehouseRAPI from "../API/WarehouseRAPI";
import PaymentEdit from "../components/PaymentEdit";
import {useNavigate} from "react-router-dom";
import {useTitle} from "../hooks/useTitle";

const Main = () => {
    Moment.locale('ru')

    useTitle(process.env.REACT_APP_TITLE)

    const navigate = useNavigate();

    const [stat, setStat] = useState({
        cashbox: 0,
        received: 0,
        revenue: 0,
        revenueMonth: 0,
    })

    const [fetchStatMain, isLoading, errorData] = useFetching(async () => {
        const res = await StatService.getMain()
        setStat(res)
        setIssues(res.issueLog)
    })
    const [issues, setIssues] = useState([])

    async function fetchIssues () {
        const res = await StatService.getIssues()
        setIssues(res)
    }

    const [modal, setModal] = useState(false)
    const [modalPaymentAdd, setModalPaymentAdd] = useState(false)

    const [payments, setPayments] = useState([])

    const [fetchPayments] = useFetching(async () => {
        setModal(true)
        const res = await PaymentService.getToday()
        setPayments(res)
    })

    function typeName(type) {
        switch (type) {
            case 1: return 'Наличные'
            case 2: return 'Терминал (эквайринг)'
            case 3: return 'Перевод на карту'
            case 4: return 'Счет'
            case 5: return 'Бикмухаметов'
            case 6: return 'Рекламный бартер'
            case 7: return 'Дисконт'
            case 8: return 'Сертификат'
            case 9: return 'Депозит'
            default: return 'Обратитесь к администратору'
        }
    }

    useEffect(() => {
        fetchStatMain()
        // fetchIssues()
    }, [])


    const [modalPaymentEdit, setModalPaymentEdit] = useState(false)
    const [editPayment, setEditPayment] = useState(null)

    useEffect(() => {
        if (editPayment != null) {
            setModalPaymentEdit(true)
        }
    }, [editPayment])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row" style={{rowGap: '25px'}}>
                    <div className="col-sm-6 col-lg-3">
                        <StatCard
                            clickableIcon={true}
                            onClickIcon={() => setModalPaymentAdd(true)}
                            title="В кассе"
                            icon="ni-fat-add"
                            value={Intl.NumberFormat("ru").format(stat.cashbox)}/>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <StatCard
                            clickable={true}
                            onClick={() => fetchPayments()}
                            title="Выручка (транзакции)"
                            value={Intl.NumberFormat("ru").format(stat.revenue)}/>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <StatCard
                            title="За месяц"
                            value={Intl.NumberFormat("ru").format(stat.revenueMonth)}/>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <StatCard
                            title="Приемка"
                            value={Intl.NumberFormat("ru").format(stat.received)}/>
                    </div>
                </div>
            </div>

            <div className="col-md-6 col-lg-6 mt-4 col-xl-4">
                <div className="card h-100">
                    <div className="card-header pb-0 p-3">
                        <div className="row">
                            <div className="col-md-6">
                                <h6 className="mb-0">Касса</h6>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                <i className="far fa-calendar-alt me-2" aria-hidden="true"/>
                                <small>{Moment().format('LL')}</small>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-3" style={{overflowX: 'auto'}}>
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th>Тип</th>
                                    <th>Сегодня</th>
                                    <th>Месяц</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stat.byType && stat.byType.map(el =>
                                    <tr>
                                        <td>{el.name}</td>
                                        <th>{Intl.NumberFormat("ru").format(el.today)}</th>
                                        <td>{Intl.NumberFormat("ru").format(el.month)}</td>
                                    </tr>
                                )}
                                <tr>
                                    <th>Выручка</th>
                                    <th>{Intl.NumberFormat("ru").format(stat.revenue)}</th>
                                    <th>{Intl.NumberFormat("ru").format(stat.revenueMonth)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div className="col-md-6 col-lg-6 mt-4 col-xl-4">
                <div className="card h-100">
                    <div className="card-header pb-0 p-3">
                        <div className="row">
                            <div className="col-md-6">
                                <h6 className="mb-0">Статистика</h6>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-3" style={{overflowX: 'auto'}}>
                        {stat.recToday &&
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th className="text-start">Тип</th>
                                    <th>Заказов</th>
                                    <th className="px-0">Изделий</th>
                                    <th>На сумму</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-start">Приемка сегодня</td>
                                    <td>{stat.recToday.count}</td>
                                    <td>{stat.recToday.items}</td>
                                    <td>{Intl.NumberFormat("ru").format(stat.recToday.sum)}</td>
                                </tr>
                                <tr>
                                    <td className="text-start">Приемка за месяц</td>
                                    <td>{stat.recMonth.count}</td>
                                    <td>{stat.recMonth.items}</td>
                                    <td>{Intl.NumberFormat("ru").format(stat.recMonth.sum)}</td>
                                </tr>
                                <tr>
                                    <td className="text-start">В работе</td>
                                    <td>{stat.inWork.count}</td>
                                    <td>{stat.inWork.items}</td>
                                    <td>{Intl.NumberFormat("ru").format(stat.inWork.sum)}</td>
                                </tr>
                                <tr>
                                    <td className="text-start">На консультации</td>
                                    <td>{stat.inCons.count}</td>
                                    <td>{stat.inCons.items}</td>
                                    <td>{Intl.NumberFormat("ru").format(stat.inCons.sum)}</td>
                                </tr>
                                <tr>
                                    <td className="text-start">Заморожено</td>
                                    <td className="text-info">{stat.freeze.count}</td>
                                    <td className="text-info">{stat.freeze.items}</td>
                                    <td className="text-info">{Intl.NumberFormat("ru").format(stat.freeze.sum)}</td>
                                </tr>
                                <tr>
                                    <td className="text-start">Просрочено</td>
                                    <td className="text-danger">{stat.desired.count}</td>
                                    <td className="text-danger">{stat.desired.items}</td>
                                    <td className="text-danger">{Intl.NumberFormat("ru").format(stat.desired.sum)}</td>
                                </tr>
                                <tr>
                                    <td className="text-start">Сегодня</td>
                                    <td className="text-warning">{stat.desiredToday.count}</td>
                                    <td className="text-warning">{stat.desiredToday.items}</td>
                                    <td className="text-warning">{Intl.NumberFormat("ru").format(stat.desiredToday.sum)}</td>
                                </tr>
                            </tbody>
                        </table>
                        }
                    </div>
                </div>
            </div>

            <div className="col-md-6 col-lg-6 mt-4 col-xl-4">
                <div className="card h-100">
                    <div className="card-header pb-0 p-3">
                        <div className="row">
                            <div className="col-md-6">
                                <h6 className="mb-0">Выдача заказов</h6>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                <i className="far fa-calendar-alt me-2" aria-hidden="true"/>
                                <small>{Moment().format('LL')}</small>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-3" style={{overflowX: 'auto'}}>
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th>Дата</th>
                                    <th>Заказ</th>
                                    <th>Клиент</th>
                                    <th>Тип выдачи</th>
                                </tr>
                            </thead>
                            <tbody>
                                {issues.map(el =>
                                    <tr key={el.item_id}>
                                        <td>{Moment(el.date).format('LT')}</td>
                                        <td onClick={() => navigate('/orders/' + el.order_id)}>{el.order_id} <span className="text-sm">{el.item_id}</span></td>
                                        <td>{el.client.last_name}</td>
                                        <td>{el.code_type}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal
                id="payments"
                title="Список транзакций"
                addClass="modal-lg"
                show={modal}
                onClose={() => setModal(false)}>
                <table className="table table-condensed text-center align-middle fw-bold">
                    <thead>
                        <tr>
                            <th className="fw-bolder">Время</th>
                            <th className="fw-bolder">Сумма</th>
                            <th className="fw-bolder">Тип</th>
                            <th className="fw-bolder">Заказ</th>
                            <th className="fw-bolder">Комментарий</th>
                        </tr>
                    </thead>
                    <tbody>
                    {payments.length > 0 &&
                        payments.map((el, k) =>
                            <tr key={el.id}>
                                <td><button className="btn btn-sm bg-gradient-primary px-2 mb-0" onClick={() => setEditPayment({...el, key: k})}>Изм.</button> {Moment(el.date).format('HH:mm')}</td>
                                <td>{Intl.NumberFormat("ru").format(el.amount)}</td>
                                <td>{typeName(Number(el.type))}</td>
                                <td onClick={() => {
                                    if (el.order_id !== '0' ) {
                                        navigate('/orders/' + el.order_id)
                                    }
                                }}>{el.order_id === '0' ? '-' : el.order_id}
                                    <br/>
                                        {el.last_name === '' ? '' : el.last_name}</td>
                                <td>{el.comment}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Modal>
            <PaymentAdd orderId={0} toPay={0} payed={() => {
                fetchStatMain()
                setModalPaymentAdd(false)
            }} modal={modalPaymentAdd} closeModal={() => setModalPaymentAdd(false)}/>
            {editPayment != null &&
                <PaymentEdit key={editPayment.id} changed={(el) => {
                    let xpayments = payments
                    xpayments[editPayment.key] = {...xpayments[editPayment.key], amount: el.amount, type: el.type}
                    fetchStatMain()
                    setPayments(xpayments)
                }} id={editPayment.id} toPay={editPayment.amount} payType={editPayment.type}
                     modal={modalPaymentEdit} closeModal={() => setModalPaymentEdit(false)}/>
            }
        </div>
    );
};

export default Main;