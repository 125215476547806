import React, {useEffect, useState} from 'react';
import QRScaner from "../components/QRScaner";
import QRScannerQuagga from "../components/QRScanerQuagga";
import Numpad from "../components/UI/numpad";
import MasterRoomRAPI from "../API/MasterRoomRAPI";
import QRMain from "../components/QRMain";

const Test = () => {
    const [ScanIsStart, setScanIsStart] = useState(true);
    const [x, setX] = useState('')
    const [item, setItem] = useState('')
    const [service, setService] = useState({})
    const [serviceList, setServiceList] = useState({})

    const fetch = async () => {
        if (item !== '') {
            setScanIsStart(false)
            const res = await MasterRoomRAPI.supplyByItem(item)
            if (Object.keys(res).length === 1) {
                setService(res[Object.keys(res)[0]])
                setX('')
            }
            if (Object.keys(res).length > 1) {
                setServiceList(res)
                setX('')
            }
            if (Object.keys(res).length === 0) {
                setScanIsStart(true)
            }
        }
    }
    const clickSupply = async (barcode) => {
        clearChoose()
        let val = 10.0

        if (service.sex === 'Мужской') {
            switch (service.code) {
                case 's28':
                case 's29':
                    val = 3.0;
                    break;
                case 's31':
                case 's32':
                case 's143':
                case 's144':
                    val = 4.0;
                    break;
                case 's33':
                    val = 7.0;
                    break;
            }
        } else {
            switch (service.code) {
                case 's28':
                case 's29':
                    val = 2.0;
                    break;
                case 's31':
                case 's32':
                case 's143':
                case 's144':
                    val = 3.0;
                    break;
                case 's33':
                    val = 5.0;
                    break;
            }
        }
        switch (service.code) {
            case 's27':
            case 's38':
                val = 2.0
                break
            case 's142':
                val = 1.0
                break
        }

        await MasterRoomRAPI.addSupply(service.id, barcode, val)
        console.log(service.id, barcode, val)
    }

    function clearChoose() {
        setItem('')
        setScanIsStart(true)
        setService({})
    }

    function fullScreen() {
        document.body.classList.toggle("fullscreen-enable")
        document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement ? document.cancelFullScreen ? document.cancelFullScreen() : document.mozCancelFullScreen ? document.mozCancelFullScreen() : document.webkitCancelFullScreen && document.webkitCancelFullScreen() : document.documentElement.requestFullscreen ? document.documentElement.requestFullscreen() : document.documentElement.mozRequestFullScreen ? document.documentElement.mozRequestFullScreen() : document.documentElement.webkitRequestFullscreen && document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    }

    useEffect(() => {
        fetch()
    }, [item])

    useEffect(() => {
        setServiceList({})
    }, [service])

    return (
        <div>
            <div className="row m-4">
                <div className="col-md-9">
                    {ScanIsStart &&
                        <>
                            <QRMain setBarcode={(s) => {
                                if (s.indexOf('_') === -1) {
                                    setItem(s)
                                } else {
                                    setItem(s.split('_')[1])
                                }
                            }}/>
                            <button className="btn p-2 bg-gradient-primary btn-xs" style={{position: 'absolute',top: 0, left: 0}} onClick={fullScreen}>[&nbsp;&nbsp;&nbsp;]</button>
                        </>
                    }

                    {!ScanIsStart &&
                        <div className="row" style={{maxHeight: 'calc(100vh - 3rem)', overflow: 'auto'}}>
                            {service.items && service.items.map((el) =>
                                <div className="col-lg-3 col-md-3" style={{padding: '5px'}}>
                                    <div className="card serviceCard" onClick={() => clickSupply(el.barcode)}
                                         style={{backgroundImage: 'linear-gradient(310deg,#2b2a29,#666)', color: '#ccc'}}>
                                        <div className="card-body p-3">
                                            <h4 className="text-center text-white m-0">{parseInt(el.barcode.slice(-3))}</h4>
                                            <h6 className="text-center text-white m-0">{el.sku}</h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {serviceList !== {} && Object.keys(serviceList).map((k) =>
                        <div className="col-lg-6" style={{padding: '5px'}}>
                            <div className="card serviceCard" onClick={() => setService(serviceList[k])}
                                 style={{backgroundImage: 'linear-gradient(310deg,#2b2a29,#666)', color: '#ccc'}}>
                                <div className="card-body p-3">
                                    <h4 className="text-center text-white m-0">{serviceList[k].label}</h4>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-md-3">
                    {service.label &&
                        <>
                            <h3 className="float-start"><span className="text-secondary text-lg">№</span>{item}</h3>
                            <button className="btn btn-sm bg-gradient-primary float-end" onClick={clearChoose}>Назад</button>
                            <h4 style={{clear: 'both'}}>{service.label}</h4>
                        </>
                    }
                    <div style={{position: 'absolute',bottom: 'calc(1.5rem - 5px)'}}>
                        <input type="text" className="form-control mb-3" style={{width: '220px'}} value={x} disabled/>
                        <Numpad add={(key) => setX(x + key)} erase={() => setX('')} ok={() => setItem(x)}/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Test;