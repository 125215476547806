import axios from "axios"

export default class ClientRAPI {
    static async searchSuggest(search, limit = 0) {
        return (await axios.get('clients/autocomplete/?search=' + search + '&limit=' + limit)).data
    }
    static async search(search, page = 1) {
        return (await axios.get('clients/?limit=20&search=' + search + '&page=' + page)).data
    }
    static async addClient(client) {
        return (await axios.post('clients/', client)).data
    }
    static async getById(id) {
        return (await axios.get('clients/' + id)).data
    }
    static async rating() {
        return (await axios.get('clients/rating/')).data
    }
}

// 26. Каленьдарь выдач (сколько пар на дату)
// 27. Правки с пальцами (сканер SC)


// 1. График смен администраторов
// 2. Заказы: Ошибка во время создания от 25.08
// 3. Заказы: Автоматическое появление окон (оплата, расходники)
// 4. Клиенты: Рейтинг клиентов, редактирование представителей
// 5. Магазин: доработать создание
// 6. Сотрудники: Ошибка при нажатии на химчистку
// 7. Распределение прав: Раздел сотрудники, Меню, Склад, Календарь
// 8. Проверка пересчёта стоимости заказа
// 9. Редактирование транзакций
// 10. Начало работ по графику смен администраторов
// 11. Пересчет бонусов Марины, когда одна в расписании
// 12. Поле поиска профилактики
// 13. Проверка добавления клиента (не закрывается автоматом)
// 14. Не перекрашивает услугу при приемке (когда несколько услуг)
// 15. Автоскрытие меню при нажатии на ссылку
// 16. Мобилка: Главная, Заказы
// 17. Мобилка: Календарь задач, Сотрудники
// 18. Мобилка: Магазин, клиенты
// 19. Изменение типа изделия (шлемы, юбки и т.п.)
// 20. Запрос на печать ценников
// 21. Восстановить работу ZRrestore
// 22. Поиск не работает в календаре задач
// 23. Срочный заказ
// 24. Тип оплаты дисконт
// 25. Ошибка с изменением срока заказа после оформления