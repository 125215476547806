import axios from "axios";

export default class ServiceRAPI {
    static async accept(serviceId: number) {
        return (await axios.post('services/' + serviceId + '/accept/')).data
    }
    static async reject(serviceId: number, comment: string) {
        return (await axios.post('services/' + serviceId + '/reject/', {
            comment: comment
        })).data
    }
    static async setPrice(serviceId: number, price: number) {
        return (await axios.post('services/' + serviceId + '/price/', {
            price: price
        })).data
    }
    static async setLabel(serviceId: number, label: string) {
        return (await axios.post('services/' + serviceId + '/label/', {
            label: label
        })).data
    }
    static async setMaster(serviceId: number, master: number) {
        return (await axios.post('services/' + serviceId + '/master/', {
            master: master
        })).data
    }
    static async addMaterial(serviceId: number, comment: string, price: number) {
        return (await axios.post('services/' + serviceId + '/material/', {
            price: price,
            comment: comment
        })).data
    }
    static async addService(data: any) {
        return (await axios.post('services/', data)).data
    }
    static async recalcWODiscount(serviceId: number) {
        return (await axios.get('services/' + serviceId + '/recalc_wo_discount/')).data
    }

    static async AllServices(groupId: number) {
        return (await axios.get('services/?group=' + groupId)).data
    }

    static async Pupupu(serviceId: number) {
        return (await axios.get('services/pupupu/' + serviceId)).data
    }
    static async AddPupupu(params: any) {
        return (await axios.post('services/pupupu/', params)).data
    }
    static async EditPupupu(id: number, params: any) {
        return (await axios.post('services/' + id, params)).data
    }

    static async WorkList(worker: string) {
        return (await axios.get('services/work_list/?worker=' + worker)).data
    }
    static async WorkWOMaster(filter: string = 'all', page: number = 1, limit: number = 20) {
        return (await axios.get('services/appoint/?filter=' + filter + '&page=' + page + '&limit=' + limit)).data
    }

    static async AllSuppliers() {
        return (await axios.get('services/suppliers')).data
    }

    static async AddSuppliers(id: number, list: {}) {
        return (await axios.post('services/' + id + '/suppliers', {
            list: list
        })).data
    }
}