import axios from "axios";

export default class StatService {
    static async getMain() {
        return (await axios.get('stat/main')).data
    }
    static async getIssues() {
        return (await axios.get('stat/issue_log')).data
    }
    static async amIAuth() {
        return (await axios.get('am_i_auth')).data
    }
    static async logout() {
        return (await axios.get('logout')).data
    }
    static async auth(phone: string, password: string) {
        return (await axios.post('auth', {
            phone: phone,
            password: password
        })).data
    }
    static async getYear(year: number) {
        return (await axios.get('stat/year?year=' + year)).data
    }
}