import React, {useEffect, useMemo, useState} from 'react';
import Calendar from "../components/Calendar";
import UserRAPI from "../API/UserRAPI";
import {useTypedSelector} from "../hooks/useTypedSelector";


const StaffSchedule = () => {
    const [selUID, setSelUID] = useState(0)
    const [schedule, setSchedule] = useState([])

    const masters = useTypedSelector(state => state.masters)

    function filterMasters(masters) {
        return masters.filter((x) => {
            return x.role_id === 1 && x.active
        })
    }

    const activeMasters = filterMasters(masters.masters)

    console.log(activeMasters)

    async function fetch()
    {
        const res = await UserRAPI.GetSchedule()
        setSchedule(res)
    }

    async function moveSchedule(uid, from, to)
    {
        const res = await UserRAPI.MoveSchedule(uid, from, to)
        fetch()
    }

    async function removeSchedule(uid, date)
    {
        const res = await UserRAPI.RemoveSchedule(uid, date)
        fetch()
    }
    async function addSchedule(date)
    {
        console.log(selUID, date)
        if (selUID !== 0) {
            const res = await UserRAPI.AddSchedule(selUID, date)
            fetch()
        }
    }

    useEffect(function () {
        fetch()
    }, [])

    return (
        <div>
            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        {useMemo(
                            () => (
                                <Calendar
                                    initialView="dayGridMonth"
                                    events={schedule}
                                    selectable
                                    editable
                                    eventResizableFromStart={false}
                                    eventDurationEditable={false}
                                    eventDrop={(e) => {
                                        moveSchedule(e.event._def.extendedProps.uid, e.oldEvent.start.toLocaleDateString('en-CA'), e.event.start.toLocaleDateString('en-CA'))
                                    }}
                                    eventClick={(e) => {
                                        removeSchedule(e.event._def.extendedProps.uid, e.event.startStr)
                                    }}
                                    dateClick={(info) => {
                                            addSchedule(info.dateStr)
                                    }}
                                />
                            ),
                            [schedule, selUID]
                        )}
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card p-3 pb-0">
                        {activeMasters.map(el =>
                            <div key={el.id} onClick={() => setSelUID(el.id)}
                                 className={'cp badge mb-3 ' + (selUID === el.id?'shadow ':'') + el.class.replace('bg-', 'bg-gradient-')}
                            >{el.fio}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffSchedule;