import axios from "axios"


export default class WarehouseRAPI {
    static async AllItems() {
        return (await axios.get('warehouse/items/')).data
    }
    static async GetItems(warehouse) {
        return (await axios.get('warehouse/' + warehouse + '/items/')).data
    }
    static async moveItem({barcode, whFrom, whTo, qty, price = 0}) {
        return (await axios.post('warehouse/move/', {
            barcode: barcode,
            whFrom: whFrom,
            whTo: whTo,
            qty: qty,
            price: price
        })).data
    }
    static async PrintPrice(barcode, count = 1) {
        return (await axios.get('warehouse/print_price/?barcode=' + barcode + '&count=' + count)).data
    }
    static async PrintPriceInner(barcode) {
        return (await axios.get('warehouse/print_price_inner/?barcode=' + barcode)).data
    }
    static async setItemPrice({barcode, price = 0}) {
        return (await axios.post('warehouse/set_price/', {
            barcode: barcode,
            price: price
        })).data
    }
}