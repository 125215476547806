import axios from "axios"


export default class UserRAPI {
    static async GetList() {
        return (await axios.get('users/')).data
    }
    static async GetSalaryInfo(userId, year, month) {
        return (await axios.get('users/' + userId + '/salary/' + year + '/' + month + '/')).data
    }
    static async GetSchedule() {
        return (await axios.get('users/schedule/')).data
    }
    static async MoveSchedule(uid, from, to) {
        return (await axios.post('users/schedule/move/', {
            uid: uid,
            from: from,
            to: to,
        })).data
    }
    static async AddSchedule(uid, date) {
        return (await axios.post('users/schedule/add/', {
            uid: uid,
            date: date
        })).data
    }
    static async RemoveSchedule(uid, date) {
        return (await axios.post('users/schedule/remove/', {
            uid: uid,
            date: date
        })).data
    }
}